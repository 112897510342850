import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogPostPreview from "../components/Blog/BlogPostGrid";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";

import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Homepage-Reviews.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Homepage-Reviews.jpg" }
    ) {
      publicURL
    }
    posts: allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          image {
            asset {
              gatsbyImageData(width: 373)
            }
          }
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
        }
      }
    }
    blogPostThumbnail22: file(
      relativePath: { eq: "blog/easy-and-cost-effective-rv-remodel-tips.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail21: file(
      relativePath: {
        eq: "blog/special-effects-props-why-movie-props-matter.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail6: file(relativePath: { eq: "blog/Why-Composites.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail5: file(
      relativePath: { eq: "blog/what-is-tooling-mold-making.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail4: file(relativePath: { eq: "blog/ul-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail3: file(
      relativePath: { eq: "blog/concept-design-rapid-prototyping.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail2: file(
      relativePath: { eq: "blog/what-is-vartm-process.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail1: file(
      relativePath: { eq: "blog/benefits-custom-fiberglass-parts.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail7: file(
      relativePath: { eq: "blog/081121_special_effects.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail8: file(relativePath: { eq: "blog/Carbon-Fiber.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail9: file(
      relativePath: { eq: "blog/Service-mobile-&-in-house-fiberglass.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail10: file(
      relativePath: {
        eq: "blog/Painted-Rhino-Vacuum-Forming-&-Thermoforming.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail11: file(
      relativePath: { eq: "blog/injection-molding-and-rotomolding.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail12: file(
      relativePath: {
        eq: "blog/What are the different types of fiberglass.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail13: file(
      relativePath: { eq: "blog/How does the VARTM method work.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail14: file(
      relativePath: { eq: "blog/Painted Rhino_ How are buses made_.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail15: file(
      relativePath: {
        eq: "blog/Painted Rhino_Pop Up Shelters for Homeless.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail16: file(
      relativePath: {
        eq: "blog/Painted-Rhino-Automotive-Fiberglass-and-the-Improved-Fiberglass-Market.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail17: file(
      relativePath: {
        eq: "blog/Painted Rhino_Aerospace parts manufacturing.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail18: file(
      relativePath: {
        eq: "blog/Painted Rhino_Service_ Concept Design & Engineering.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail20: file(
      relativePath: {
        eq: "blog/painted-rhino-light-resin-transfer-molding-what-is-it-and-why-you-should-consider-using-it.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPostThumbnail19: file(
      relativePath: {
        eq: "blog/Painted_Rhino_Mass_Transit_Component_Manufacturing.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 373) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ArchivePage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout
        headerStyle="standard"
        headerLinkColor="dark"
        headerHasBorder={false}
      >
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout
      headerStyle="standard"
      headerLinkColor="dark"
      headerHasBorder={false}
    >
      <SearchEngineOptimization
        title="Blog | Painted Rhino"
        description=""
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-12 md:pt-16 mb-20 md:mb-32">
        <div className="container"></div>
        <div className="container">
          <header className="mb-10 md:mb-12">
            <h1>Painted Rhino Blog</h1>
          </header>
          {postNodes && postNodes.length > 0 && (
            <BlogPostPreview nodes={postNodes} />
          )}
        </div>
      </section>
      <CTABackgroundImage />
    </Layout>
  );
};

export default ArchivePage;
